'use client'

import ArrowForward from '@/app/_components/Icon/ArrowForward'
import I18nTranslatorClient from '@/app/_components/Shared/I18nTranslator/I18nTranslatorClient'
import { Button } from '@/app/_primitives/Button'
import { Player } from '@lottiefiles/react-lottie-player'
import { useRouter } from 'next/navigation'
import lottiefile from './inn_champy_idle.json'

export function GoHomeBtn() {
  const router = useRouter()
  return (
    <div className="flex flex-col items-center justify-center gap-40">
      <Player
        autoplay
        controls
        loop
        src={lottiefile}
        className="!mt-20 min-w-300 lg:w-416"
      />
      <Button
        type="button"
        color="butterScotch"
        size="lg"
        className="flex h-72 w-240"
        onClick={() => router.push('/')}>
        <I18nTranslatorClient text="common.goHome" />{' '}
        <ArrowForward fill="#3C3C3C" />
      </Button>
    </div>
  )
}
